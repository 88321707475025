import React from 'react';
import { Layout } from '../components/Layout';
import { ArticleItem } from '../components/ArticleItem';
import styled from 'styled-components';
import { SectionHeader } from '../components/SectionHeader';
import { LinkButton } from '../components/LinkButton';
import { device } from '../theme';
import { SEO } from '../components/SEO';
import _ from 'lodash';

const StyledArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const StyledPagination = styled.div`
  margin: 20px 0;
  a {
    margin-right: 20px;
  }
`;

const StyledSectionHeader = styled(SectionHeader)``;

interface Props {
  pageContext: any;
}
export const CategoryArticleList = ({ pageContext }: Props) => {
  const {
    articles,
    numArticlePages,
    currentPage,
    basePath,
    category,
  } = pageContext;
  return (
    <Layout>
      <SEO title="Articles" />
      <StyledSectionHeader style={{ marginBottom: '50px' }} forwardedAs="h1">
        {category} Articles
      </StyledSectionHeader>
      <StyledArticleGrid>
        {articles.map((article: any) => {
          return (
            <ArticleItem
              key={article.node.id}
              imageSize="large"
              article={article.node}
            />
          );
        })}
      </StyledArticleGrid>
      <StyledPagination>
        {currentPage > 1 && (
          <LinkButton
            to={`/category/${_.kebabCase(category)}/${
              currentPage === 2 ? '' : currentPage - 1
            }`}
          >
            Previous Page
          </LinkButton>
        )}
        {numArticlePages > 1 && currentPage != numArticlePages && (
          <LinkButton
            to={`/category/${_.kebabCase(category)}/${currentPage + 1}`}
          >
            Next Page
          </LinkButton>
        )}
      </StyledPagination>
    </Layout>
  );
};

export default CategoryArticleList;
